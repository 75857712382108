<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>企业用户管理</p>
			<i>/</i>
			<p @click="jumpPage">企业用户列表</p>
			<i>/</i>
			<p class="active">企业用户编辑</p>
		</nav>
	
		<div class="page_edit">
			
			<h4 class="h4">基础信息</h4>
			
			<div class="page_edit_form">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
					<el-form-item label="" prop="company">
						<p class="form_label_p">企业名称</p>
						<el-input class="input_long" v-model="ruleForm.company" placeholder="请输入企业名称(文本20)"></el-input>
					</el-form-item>
					<el-form-item label="" prop="contacts">
						<p class="form_label_p">联系人姓名</p>
						<el-input class="input_long" v-model="ruleForm.contacts" placeholder="请输入联系人">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="mobile">
						<p class="form_label_p">手机号</p>
						<el-input class="input_long" v-model="ruleForm.mobile" maxlength="11" placeholder="请输入手机号">
						</el-input>
					</el-form-item>
					<el-form-item label="" prop="invited_code">
						<p class="form_label_p">归属代理商</p>
						<el-select class="input_long" v-model="ruleForm.invited_code" filterable placeholder="请选择">
							<el-option v-for="item in roleLidt" :key="item.invite_code"
								:label="item.agent_name" :value="item.invite_code">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="" prop="sex">
						<p class="form_label_p">状态</p>
						<el-radio-group v-model="ruleForm.status">
							<el-radio :disabled="disabled" label="1">激活</el-radio>
							<el-radio :disabled="disabled" label="0">冻结</el-radio>
						</el-radio-group>
					</el-form-item>
					<div  class="el-form-item_last"></div>
					<el-form-item class="el-form-item-preservation">
						<div class="page_edit_preservation">
							<p class="p1" @click="upPage">返回</p>
							<p class="p2" v-if="modifyBtn" @click="createForm('ruleForm')">保存</p>
							<p class="p2" v-else @click="editForm('ruleForm')">保存</p>
						</div>
					</el-form-item>
				</el-form>
			</div>
	
		</div>
	
	</div>
</template>

<script>
	
	export default {
		name: 'enterpriseUserEdit',
		data() {
			return {
				ruleForm: {
					company: '', // 企业名称
					mobile: '', // 手机号
					contacts: '', // 联系人
					invited_code: '', // 归属代理商
					status: '1',  // 状态
				},
				rules: {
					company: [{
						required: true,
						message: '名称不能为空',
						trigger: 'blur'
					}],
					mobile: [{
							required: true,
							message: '手机号不能为空',
							trigger: 'blur'
						},
						{
							pattern: /^1[3456789]\d{9}$/,
							message: '手机号错误'
						}
					],
					contacts: [{
						required: true,
						message: '联系人不能为空',
						trigger: 'blur'
					}],
					invited_code: [{
						required: true,
						message: '请选择归属代理商',
						trigger: 'change'
					}],
				},
				modifyBtn: true, // 默认创建
				roleLidt: [],  // 代理商角色
				disabled: false, // 是否可修改
			}
		},
		components: {
			
		},
		created() {
			if (this.$route.query.id) {
				this.modifyBtn = false;
				
				// 获取企业用户详情做修改
				this.getCustomuserInfo();
			}
			
			// 获取代理商列表
			this.getAgentList()
		},
		methods: {
			
			// 获取代理商列表
			getAgentList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}agent/agentlist`,
					data: {}
				}).then( res => {
					if(res.code == '0'){
						this.roleLidt = res.data;
					}
				})
			},
			
			// 获取企业用户详情做修改
			getCustomuserInfo(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}customuser/detail`,
					data: {
						id: this.$route.query.id,
					}
				}).then( res => {
					if (res.code == '0') {
						this.ruleForm.company = res.data.company;
						this.ruleForm.mobile = res.data.mobile;
						this.ruleForm.contacts = res.data.contacts;
						this.ruleForm.invited_code = res.data.invited_code;
						this.ruleForm.status = res.data.status;
					} 
				})
			},

			// 创建
			createForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}customuser/add`,
							data: {
								company: this.ruleForm.company,
								mobile: this.ruleForm.mobile,
								contacts: this.ruleForm.contacts,
								invited_code: this.ruleForm.invited_code,
								status: this.ruleForm.status,
								parent_id: '0',
								is_child: '0',
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '创建成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
						
						
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 修改
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$sendHttp({
							method: 'POST',
							url: `${process.env.VUE_APP_SAAS}customuser/edit`,
							data: {
								id: this.$route.query.id,
								company: this.ruleForm.company,
								mobile: this.ruleForm.mobile,
								contacts: this.ruleForm.contacts,
								invited_code: this.ruleForm.invited_code,
								status: this.ruleForm.status,
								parent_id: '0',
								is_child: '0',
							}
						}).then( res => {
							if (res.code == '0') {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
								this.$router.go(-1);
							} else {
								this.$message.error(`${res.msg}`);
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			// 返回上一页
			upPage() {
				this.$confirm('系统可能不会保存您所填写的信息。', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					this.$router.go(-1);
				}).catch(() => {});
			},
			
			// 头部跳转页面
			jumpPage(){
				this.$router.go(-1);
			},

		}
	}
</script>
<style scoped lang="less">
	/* 加长input */
	.input_long_plus {
		width: 500px;
	}

	/* 长input */
	.input_long {
		width: 400px;
	}

	/* 中等input */
	.input_medi {
		width: 200px;
	}

	/* mini input */
	.input_mini {
		width: 100px;
	}
	
</style>
